import Select from 'react-select';
import { FC, useCallback } from "react";
import { Formik, FormikHelpers } from 'formik';
import { TextField } from "../../components/formik/TextField/TextField";
import { Button } from "../../components/Button/Button";
import * as Yup from 'yup';
import { PhoneMaskField } from "../../components/formik/PhoneMaskField/PhoneMaskField";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { useMutation } from "@tanstack/react-query";
import updateUserById from "../../api-calls/updateUserById";
import { FormStatusErrors } from "../../components/formik/FormStatusErrors";
import { AxiosResponse } from "axios";
import { Spinner } from "../../components/Spinner/Spinner";

interface ConfirmationFormValues {
  firstName: string,
  lastName: string,
  email: string;
  suffix?: string | null;
  npi?: string | null;
  phone?: string | null;
}

type Option = {
  value: string;
  label: string;
};

const suffixOptions: Option[]= [
  { value: 'None', label: 'None' },
  { value: 'MD', label: 'MD' },
  { value: 'DO', label: 'DO' },
  { value: 'PA', label: 'PA' },
  { value: 'NP', label: 'NP' },
  { value: 'DDS', label: 'DDS' },
  { value: 'RN', label: 'RN' },
  { value: 'LVN', label: 'LVN' },
]

const validationSchema: Yup.ObjectSchema<ConfirmationFormValues> = Yup.object({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  suffix: Yup.string().nullable(),
  phone: Yup.string().nullable(),
  email: Yup.string().required("Required").email("Please enter a valid email address"),
  npi: Yup.string().nullable().matches(/^\d{10}$/, "Please enter a valid NPI number with exactly 10 digits and no special characters"),
})

type ConfirmationFormProps = {
  onConfirmationFormSuccess: (data: AxiosResponse) => void,
}

export const ConfirmationForm:  FC<ConfirmationFormProps> = (props) => {
  const { onConfirmationFormSuccess } = props
  const { data } = useCurrentUser();

  const userData = data?.data;
  const updateDataMutation = useMutation({mutationFn: (confirmFormData: object) => updateUserById(userData?.id, confirmFormData)});

  const onSubmit = useCallback(async(values: ConfirmationFormValues, formikActions: FormikHelpers<ConfirmationFormValues>) => {
    const { setStatus, setSubmitting } = formikActions;

    const updateData = {
      ...userData,
      first_name: values.firstName,
      last_name: values.lastName,
      external_identifiers: [
        {
          identifier_name: "npi",
          identifier: values.npi,
        },
      ],
      phone: values.phone,
      suffix: values.suffix,
    };

    if (!userData.is_sso) {
      updateData.email = values.email;
      updateData.username = values.email;
    }
    updateDataMutation.mutate(
      updateData,
      {
        onSuccess: onConfirmationFormSuccess,
        onError: (error: any) => {
          const errors = [{
            key: "",
            message: `Something went wrong. ${error.response.data.message}`,
          }]
          const specificErrors = error.response?.data?.errors;
          if (specificErrors) {
            Object.keys(specificErrors).forEach(key => {
              errors.push({
                key: "",
                message: specificErrors[key][0],
              });
            });
          }
          setStatus({
            errors: errors,
          });
        },
        onSettled: () => {
          setSubmitting(false);
        },
      }
    );
  }, [userData]);


  return (
    <div className="ConfirmationForm px-2 w-full sm:w-10/12">
      <Formik<ConfirmationFormValues>
      initialValues={{  firstName: userData?.first_name || '',
        lastName: userData?.last_name || '',
        email: userData?.email || '',
        npi: (userData?.external_identifiers?.find((identifier: { identifier_name: string }) => identifier.identifier_name === 'npi')?.identifier || ''),
        phone: userData?.phone || '',
        suffix: userData?.suffix || '' }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      >
        {({
        values,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        status
        }) => (
        <form onSubmit={handleSubmit}>
          <FormStatusErrors status={status} />
          <div className="grid grid-cols-5 gap-4">
            <div className="col-span-5 md:col-span-2">
              <TextField
                label="First Name"
                name="firstName"
                placeholder="Jane"
                type="text"
              />
            </div>
            <div className="col-span-5 md:col-span-2">
              <TextField
                label="Last Name"
                name="lastName"
                placeholder="Doe"
                type="text"
              />
            </div>
            <div className="col-span-5 md:col-span-1">
              <label htmlFor="suffix" className="block font-medium text-gray-700 mb-1">Suffix</label>
              <Select
                options={suffixOptions}
                name="suffix"
                value={suffixOptions.find((option) => option.value === values?.suffix)}
                onChange={(selectedOption) => setFieldValue("suffix", selectedOption?.value)}
              />
            </div>
            { !userData?.is_sso &&
              <div className="col-span-5">
                <TextField
                  name="email"
                  label="Email Address"
                  type="text"
                />
              </div>
            }
            <div className="col-span-5">
              <PhoneMaskField
                name="phone"
                label="Primary Phone"
                placeholder="(___) ___-____"
                indicateOptional
              />
            </div>
            <div className="col-span-5">
              <TextField
                label="NPI"
                name="npi"
                placeholder="5555555555"
                type="text"
                indicateOptional
              />
            </div>
          </div>
          <div className="mt-8">
            <span className="block w-full rounded-md shadow-sm">
              <Button
                type="submit"
                disabled={updateDataMutation?.isLoading}
                color="blue"
                className="w-1/5 float-right"
              >
                Next
                {updateDataMutation?.isLoading &&
                  <Spinner className="ml-1" size={10} color="light-blue" borderSize={2}/>
                }
              </Button>
            </span>
          </div>
        </form>
        )}
      </Formik>
    </div>
  )
}